import styled from '@emotion/styled';

const Content = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center,',
  maxWidth: '31rem',
  textAlign: 'center',
  width: '100%',
});

export default Content;
