import H4Component from 'primitives/Typography/Headings/H4';
import styled from '@emotion/styled';

const H4 = styled(H4Component)({
  marginBottom: '2rem',
  marginTop: '2.4rem',
  textAlign: 'center',
});

export default H4;
